<template>
  <div class="ProblemListTable">
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      :height="height"
      @selection-change="handleSelectionChange"
      @row-click="rowDblclick"
      @sort-change="courseSortChange" 
      :row-key="(row) => row.id"
    >
      <el-table-column
        type="selection"
        width="55"
        align="center"
        v-if="isSelection"
      >
      </el-table-column>
      <el-table-column
        label="团队名称"
        align="center"
        sortable="name"
        prop="name"
        width="320"
        show-overflow-tooltip
      >
        <!-- <template slot-scope="scope">
          <span class="ProblemList-name">{{ scope.row.name }}</span></template
        > -->
        <!-- <template slot-scope="scope">
          <el-tooltip effect="dark" :content="scope.row.name" placement="top" v-if="scope.row.name.length > 9">
            <span class="ProblemList-name">{{ scope.row.name.substring(0, 9) + "..." }}</span>
          </el-tooltip>
          <span class="ProblemList-name" v-else>{{ scope.row.name }}</span>
        </template> -->
      </el-table-column>
        
        
      <el-table-column label="创建人" prop="user_realname" width="120" sortable="user_realname" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.user_realname }}</span>
        </template>
      </el-table-column>
      <el-table-column label="管理员人数" width="140" align="center">
        <template slot-scope="scope">{{ scope.row.manager_user_count }}人</template>
      </el-table-column>
      <el-table-column label="成员人数" width="120" align="center">
        <template slot-scope="scope">{{ scope.row.member_user_count }}人</template>
      </el-table-column>
        
      <el-table-column label="类别" sortable="type" prop="type" width="200" align="center">
        <template slot-scope="scope">{{
          scope.row.type_name ? scope.row.type_name : "--"
        }}</template>
      </el-table-column>
      <el-table-column label="创建时间" width="180" sortable="create_time" prop="create_time" align="center">
        <template slot-scope="scope">{{
          scope.row.create_time_format
        }}</template>
      </el-table-column>
      <el-table-column label="状态" sortable="from_id"  prop="from_id" v-if="dialogVisible" minWidth="100"  align="center">
        <template slot-scope="scope">{{
          scope.row.f_valid_s
        }}</template>
      </el-table-column>
      <el-table-column
        
        label="操作"
        align="center"
        v-if="
          (operationType === 'copyAndDownload' && userType === 2 && !dialogVisible) ||
          userType === 1
        "
      >
        <el-button-group
          style="
            display: inline-flex;
            align-items: center;
            justify-content: center;
          "
          slot-scope="scope"
        >
          <el-button
            type="text"
            size="mini"
            v-if="userType === 2 || userType === 1"
            @click.stop="$handleRoute({ team_id: scope.row.id }, 'TeamInfo')"
          >
            进入主页
          </el-button>
          <el-button
            :disabled="!$isPowers('teamCopy')"
            type="text"
            size="mini"
            v-if="userType === 2 || userType === 1"
            @click.stop="$handleRoute({ cid: scope.row.id }, 'TeamCreate')"
          >
            拷贝创建
          </el-button>
          <el-button
            type="text"
            size="mini"
            v-if="scope.row.is_manager"
            @click.stop="$handleRoute({ cid: scope.row.id,isEdit:true }, 'teamEdit')"
            :disabled="!$isPowers('teamEdit')"
          >
            编辑基本信息
          </el-button>
          <el-button
            type="text"
            size="mini"
            v-if="scope.row.user_id !== userInfo.id && !scope.row.is_member"
            :disabled="!$isPowers('teamAdd')"
            @click.stop="clickAddJoin(scope.row.id)"
          >
            报名学习
          </el-button>
          <el-button
            type="text"
            size="mini"
            v-if="scope.row.user_id !== userInfo.id && scope.row.is_member"
            :disabled="!$isPowers('teamDrop')"
            @click.stop="clickOutJoin(scope.row.id)"
          >
            申请退出
          </el-button>
          
          <el-button
            type="text"
            size="mini"
            v-if="scope.row.user_id === userInfo.id && scope.row.course_id==0"
            @click.stop="clickDelete(scope.row.id)"
            :disabled="!$isPowers('teamDel')"
          >
            删除团队
          </el-button>
          <el-button
            type="text"
            size="mini"
            v-if="scope.row.is_creator"
            @click.stop="transferTeam(scope.row.id)"
            :disabled="!$isPowers('teamEdit')"
          >
          创建人变更
          </el-button>
          <!-- <el-link
            icon="el-icon-delete-solid"
            style="font-size: 20px; padding: 4px 0"
            v-if="scope.row.user_id === userInfo.id"
            @click.stop="clickDelete(scope.row.id)"
            size="mini"
            :disabled="!$isPowers('teamDel')"
            :underline="false"
          >
          </el-link> -->
          <!-- <el-link
            icon="el-icon-switch-button"
            style="font-size: 20px; padding: 4px 0"
            v-if="scope.row.user_id !== userInfo.id && scope.row.is_member"
            :disabled="!$isPowers('teamDrop')"
            @click.stop="clickOutJoin(scope.row.id)"
            :underline="false"
            size="mini"
          >
          </el-link> -->
          <!-- <el-link
              icon="el-icon-circle-plus-outline"
              style="font-size: 20px;padding: 4px 0;"
              v-if="scope.row.user_id !== userInfo.id && !scope.row.is_member"
              :disabled="!$isPowers('teamAdd')"
              @click.stop="clickAddJoin(scope.row.id)"
			  :underline="false"
              size="mini">
          </el-link> -->
        </el-button-group>
      </el-table-column>
    </el-table>
    <el-dialog title="创建人变更" width="30%" :visible.sync="transferDialog">
      <div style="display: flex; align-items: center;justify-content: center">
            <div class="team">请选择新的创建人：</div>
            <el-select
              clearable
              v-loading="teamDataLoading"
              v-model="userIdvalue"
              @change="changeUser"
              placeholder="请选择"
            >
              <el-option
                v-for="item in userData"
                :key="item.user_id"
                :label="item.realname"
                :value="item.user_id"
              >
              </el-option>
            </el-select>
          </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="transferDialog = false"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="confirmTransfer"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { teamList, deleteTeam, joinTeam, quit_group } from "@/api/team.js";
export default {
  props: {
    operationType: {
      //操作按钮类型 copyAndDownload
      type: String,
      default: () => "",
    },
    isSelection: {
      //要不要勾选框
      default: () => true,
    },
    //弹窗
    dialogVisible: {
      default: () => false,
    },
    // 高度
    height: {
      default: () => 0,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      multipleSelection: [],
      sort:"",
      ids:0,//勾选的团队
      userData:[],
      transferDialog:false,
      userId:'',
      userIdvalue:'',
      teamDataLoading:false
    };
  },
  created(){
  },
  methods: {
    // 团队排序
     courseSortChange(column){
      // console.log(column,"column")
      const orderData=column.order == "descending" ? "desc" : column.order == "ascending" ? "asc" : null
      // console.log(orderData,"orderData")
      this.sort=column.prop+'-'+orderData
      // console.log(this.sort,"this.sort")
      this.$emit('getList',this.sort)
    },
    // 删除团队
    clickDelete(id) {
      if (!this.userInfo.id) {
        return (this.$store.state.lodinShow = true);
      }
      this.$confirm("确定要删除此团队?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteTeam({ group_id: id })
            .then(({ data }) => {
              console.log(data, "删除成功");
              this.$message.success("删除成功");
              this.$parent.getTeamList();
            })
            .catch((err) => {
              console.log(err, "删除失败");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 团队转让
    transferTeam(id){
      this.groupId=id
      this.transferDialog=true
      this.teamDataLoading=true
      this.$http
        .post(
          `xapi/user.group/memberList?page=1`+
            "&pageSize=99" +
            "&team_id=" +
            this.groupId +
            "&type=0"
        )
        .then((res) => {
          this.teamDataLoading=false
          this.userData=res.data.data
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
     // 确定转让
     confirmTransfer(){
      this.$http
        .post("/xapi/user.group/teamTransfer", {
          group_id: this.groupId,
          user_id: this.userIdvalue,
        })
        .then((res) => {
          this.$message.success('转让成功！')
          this.transferDialog=false
          this.$parent.getTeamList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
     // 选择转让人
     changeUser(e){
      console.log(e,"ee")
      this.userIdvalue=e
    },
    // 加入团队
    clickAddJoin(id) {
      if (!this.userInfo.id) {
        return (this.$store.state.lodinShow = true);
      }
      this.$confirm("确定要加入此团队?", "加入", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          joinTeam({ group_id: id })
            .then(({ data }) => {
              console.log(data, "加入成功");
              this.$message.success("已提交申请加入");
              this.$parent.getTeamList();
            })
            .catch((err) => {
              console.log(err, "加入失败");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消申请",
          });
        });
    },
    // 退出团队
    clickOutJoin(id) {
      if (!this.userInfo.id) {
        return (this.$store.state.lodinShow = true);
      }
      this.$confirm("确定要退出此团队?", "加入", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          quit_group({ group_id: id }).then(({ data }) => {
            console.log(data, "退出成功");
            this.$message.success("退出成功");
            this.$parent.getTeamList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
    // 点击详情
    rowDblclick(row) {
      if (this.userInfo.id && !this.userInfo.type) {
        this.$store.state.showBinding = true;
      } else {
        if(this.dialogVisible)return
        this.$handleRoute({ team_id: row.id }, "TeamInfo");
      }
    },
    handleSelectionChange(val) {
      console.log(val, "valvalval");
      this.ids = val.map((v) => {
        return v.id;
      });
      console.log(this.ids, "this.idsthis.ids");
      this.$emit('chooseId',this.ids)
      // this.multipleSelection = val;
    },
  },
};
</script>

<style lang="less" scoped>
.ProblemListTable {
  ::v-deep {
    .el-table tr {
      height: 51px;
    }
    .el-table__row {
      cursor: pointer;
    }
    .el-table thead {
      color: #000;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #00957e;
      border-color: #00957e;
    }
    .el-checkbox__inner:hover {
      border-color: #00957e;
    }
    .el-table td,
    .el-table th {
      padding: 10px 0;
    }
  }
  text-align: center;

  .download {
    width: 20px;
    height: 20px;
  }
  .operation {
    display: inline-flex;
    align-items: center;
    height: 26px;

    cursor: pointer;
    .copy {
      cursor: pointer;
      background: #00957e;
      border-radius: 2px;
      color: #fff;
      line-height: 18px;
      font-size: 14px;
      padding: 4px 8px;
      margin-right: 12px;
    }
  }
  .ProblemList-name {
    color: #00957e;
  }
}
</style>